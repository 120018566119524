<template>
  <div>
    <b-card no-body>
      <offer-summary />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürünler</b-card-title>
      </b-card-header>
      <products />
    </b-card>
    <b-card title="Özel Şartlar">
      <terms />
      <template v-if="userData.id_dealers">
        <template v-if="userData.id_dealers === dataItem.id_dealers">
          <div class="mt-1 text-center d-flex justify-content-between">
            <div class="text-left">
              <div class="d-inline">
                <b-button
                  variant="warning"
                  class="mr-2"
                  :to="'/app/offers/edit/' + dataItem.id"
                >
                  <FeatherIcon icon="EditIcon" />
                  Güncelle
                </b-button>
              </div>
              <div class="d-inline">
                <b-button
                  variant="info"
                  class="mr-2"
                  :to="'/app/offers/revised/' + dataItem.id"
                >
                  <FeatherIcon icon="EditIcon" />
                  Revize Oluştur
                </b-button>
              </div>
              <div class="d-inline">
                <b-button
                  variant="success"
                  class="mr-2"
                  :disabled="dataItem.id_offer_statuses !== '3'"
                  @click="offer2Proforma"
                >
                  <FeatherIcon icon="FileIcon" />
                  Proforma Oluştur
                </b-button>
              </div>
            </div>
            <div class="text-right">
              <div class="d-inline">
                <b-button
                  :href="downloadUrl"
                  target="_blank"
                  variant="success"
                >
                  <FeatherIcon icon="PrinterIcon" />
                  Yazdır
                </b-button>
              </div>
              <div class="d-inline">
                <b-button
                  variant="danger"
                  class="ml-1"
                  @click="toggleMailForm"
                >
                  <FeatherIcon icon="MailIcon" />
                  E-Posta
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="mt-1 text-center d-flex justify-content-between">
          <div class="text-left">
            <div class="d-inline">
              <b-button
                variant="warning"
                class="mr-2"
                :to="'/app/offers/edit/' + dataItem.id"
              >
                <FeatherIcon icon="EditIcon" />
                Güncelle
              </b-button>
            </div>
            <div class="d-inline">
              <b-button
                variant="info"
                class="mr-2"
                :to="'/app/offers/revised/' + dataItem.id"
              >
                <FeatherIcon icon="EditIcon" />
                Revize Oluştur
              </b-button>
            </div>
            <div class="d-inline">
              <b-button
                variant="success"
                class="mr-2"
                :disabled="dataItem.id_offer_statuses !== '3'"
                @click="offer2Proforma"
              >
                <FeatherIcon icon="FileIcon" />
                Proforma Oluştur
              </b-button>
            </div>
          </div>
          <div class="text-right">
            <div class="d-inline">
              <b-button
                :href="downloadUrl"
                target="_blank"
                variant="success"
              >
                <FeatherIcon icon="PrinterIcon" />
                Yazdır
              </b-button>
            </div>
            <div class="d-inline">
              <b-button
                variant="danger"
                class="ml-1"
                @click="toggleMailForm"
              >
                <FeatherIcon icon="MailIcon" />
                E-Posta
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-card>
    <template v-if="userData.id_dealers">
      <template v-if="userData.id_dealers === dataItem.id_dealers">
        <mail-form
          v-if="emailForm"
        />
        <mail-logs />
      </template>
    </template>
    <template v-else>
      <mail-form
        v-if="emailForm"
      />
      <mail-logs />
    </template>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BButton,
} from 'bootstrap-vue'
import OfferSummary from '@/views/Admin/Offers/View/OfferSummary.vue'
import Products from '@/views/Admin/Offers/View/Products.vue'
import Terms from '@/views/Admin/Offers/View/Terms.vue'
import MailLogs from '@/views/Admin/Offers/View/MailLogs.vue'
import MailForm from '@/views/Admin/Offers/View/MailForm.vue'

export default {
  name: 'OffersView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    OfferSummary,
    Products,
    Terms,
    MailLogs,
    MailForm,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      emailForm: false,
      downloadUrl: `${this.$store.state.app.baseURL}/Exports/offers/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}`,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
  methods: {
    toggleMailForm() {
      this.emailForm = !this.emailForm
    },
    offer2Proforma() {
      this.$store.dispatch('proformaInvoices/offer2Proforma', this.$route.params.id)
        .then(res => {
          if (res.id) {
            this.$router.push(`/app/proforma-invoices/edit/${res.id}`)
          }
        })
    },
  },
}
</script>
