<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>E-Posta Geçmişi</b-card-title>
    </b-card-header>
    <b-card-body v-if="dataList.length === 0">
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" /> E-Posta gönderim geçmişi bulunmamaktadır.
        </div>
      </b-alert>
    </b-card-body>
    <b-table
      v-else
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(mailsubject)="data">
        <div class="text-primary">
          {{ data.item.mailsubject }}
        </div>
        <div class="text-muted">
          <div v-html="data.item.mailbody" />
        </div>
      </template>
      <template #cell(mailto)="data">
        <div class="text-primary text-nowrap">
          <strong>Kime: </strong>{{ data.item.mailto }}
        </div>
        <div class="text-muted font-small-2 text-nowrap">
          <strong>Bilgi: </strong>{{ data.item.mailcc }}
        </div>
        <div class="text-muted font-small-2 text-nowrap">
          <strong>Gizli: </strong>{{ data.item.mailbcc }}
        </div>
      </template>
      <template #cell(created)="data">
        <div class="text-info font-small-2 text-nowrap">
          {{ moment(data.item.created).format('LLLL') }}
        </div>
        <div class="text-muted">
          {{ data.item.username }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BTable, BAlert, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'MailLogs',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BAlert,
    BCardBody,
  },
  data() {
    return {
      fields: [
        {
          key: 'mailsubject',
          label: 'E-POSTA',
        },
        {
          key: 'mailto',
          label: 'KİME',
        },
        {
          key: 'created',
          label: 'TARİH',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['offerMailsend/dataList']
    },
  },
  created() {
    this.getDataList()
    this.$store.commit('offerMailsend/RESET_DATA_ITEM')
  },
  methods: {
    getDataList() {
      this.$store.dispatch('offerMailsend/getDataList', {
        where: {
          'offer_mailsend.id_offers': this.$route.params.id,
        },
      })
    },
  },
}
</script>
