<template>
  <div>
    <template v-if="dataItem.lines">
      <b-table-simple hover>
        <b-thead>
          <b-tr>
            <b-th>
              ÜRÜN ADI
            </b-th>
            <b-th class="text-right">
              MİKTAR
            </b-th>
            <b-th class="text-right">
              BİRİM FİYAT
            </b-th>
            <b-th class="text-right">
              TOPLAM
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="line in dataItem.lines"
            :key="line.id"
          >
            <b-td>
              <div>{{ line.title }}</div>
              <div class="font-small-2 text-info">
                {{ line.content }}
              </div>
            </b-td>
            <b-td class="text-nowrap text-right">
              {{ line.quantity }} {{ line.unit }}
            </b-td>
            <b-td class="text-nowrap text-right">
              {{ line.listed_price | toCurrency }} {{ line.currency }}
            </b-td>
            <b-td class="text-nowrap text-right">
              <div v-if="line.listed_total > line.discounted_total">
                <div :class="line.discounted_total > 0? 'strikethrough' : ''">
                  {{ line.listed_total | toCurrency }} {{ line.currency }}
                </div>
                <div
                  class="font-weight-bold"
                  :class="line.discounted_total > 0? 'text-success' : ''"
                >
                  {{ line.total_price | toCurrency }} {{ line.currency }}
                </div>
              </div>
              <div
                v-else
                class="font-weight-bold"
              >
                {{ line.total_price | toCurrency }} {{ line.currency }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th>Toplam Tutar</b-th>
            <b-th
              class="text-right"
              colspan="3"
            >
              {{ dataItem.listed_total | toCurrency }} {{ dataItem.lines[0].currency }}
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>Müşteriye Özel Fiyat</b-th>
            <b-th
              class="text-right"
              colspan="3"
            >
              {{ dataItem.discounted_total | toCurrency }} {{ dataItem.lines[0].currency }}
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>
              KDV
              <span
                v-for="(tax_rate,key) in dataItem.tax_rates"
                :key="key"
              >%{{ tax_rate }}</span>
            </b-th>
            <b-th
              class="text-right"
              colspan="3"
            >
              {{ dataItem.taxes_total | toCurrency }} {{ dataItem.lines[0].currency }}
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>Genel Toplam Tutar</b-th>
            <b-th
              class="text-right"
              colspan="3"
            >
              {{ dataItem.grand_total | toCurrency }} {{ dataItem.lines[0].currency }}
            </b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </template>
    <div
      v-else
      class="m-2"
    >
      <b-alert
        show
        variant="danger"
      >
        <div class="alert-body text-center">
          Teklif içeriğinde ürün bulunamadı.
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {
  BTableSimple, BTbody, BTd, BTh, BThead, BTr, BTfoot, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'Products',
  components: {
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTbody,
    BTd,
    BTfoot,
    BAlert,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
<style scoped>
.strikethrough{
  text-decoration: line-through !important;
}
</style>
